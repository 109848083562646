import { colors } from "@shared/styles/theme";

import {
	EContentPagesContentBlockCflBgColor,
	EContentPagesContentBlockCflDisplayOptions,
	EContentPagesContentBlockCflLayout,
} from "./type";

export const convertBackgroundColorInHexCode = (bgColor?: EContentPagesContentBlockCflBgColor) => {
	if (!bgColor) return colors.transparent;

	if (bgColor === EContentPagesContentBlockCflBgColor.Grey) {
		return colors.grey;
	}

	if (bgColor === EContentPagesContentBlockCflBgColor.White) {
		return colors.white;
	}
	return colors.transparent;
};

export const normalizeLayout = (layout?: EContentPagesContentBlockCflLayout) => {
	if (!layout) return;
	return (
		layout.charAt(0).toLowerCase() + layout.replace(/\s+|:/g, "").replace(/-/g, "_").substring(1)
	);
};

export const isRightMarginRemoved = (displayAs?: EContentPagesContentBlockCflDisplayOptions) => {
	return displayAs === EContentPagesContentBlockCflDisplayOptions.FeaturedLinks;
};

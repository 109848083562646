import React from "react";

import HighlightedText from "@components/ContentfulComponents/ComponentHighlightedText/HighlightedText.component";
import { IHighlightedTextCflData } from "@components/ContentfulComponents/ComponentHighlightedText/type";
import { isHighlightedText } from "@shared/contentful/contentful.utils";
import { normalizeLayout } from "@components/ContentfulComponents/ContentPagesContentBlock/utils";
import { EContentPagesContentBlockCflLayout } from "@components/ContentfulComponents/ContentPagesContentBlock/type";

import styles from "./highlights-block.module.scss";

interface IProps {
	data: IHighlightedTextCflData[];
	layout?: EContentPagesContentBlockCflLayout;
}

const HighlightsBlock = ({ data, layout }: IProps) => {
	if (!data || data.length === 0) return null;

	const normalizedLayout = normalizeLayout(layout);

	return (
		<div
			className={`${styles.wrapper} ${normalizedLayout ? styles[normalizedLayout] : ""}`}
			data-testid="HighlightsBlock_wrapper"
		>
			{data.map((item) => {
				if (!item || !isHighlightedText(item)) return null;
				return <HighlightedText key={item.sys?.id} data={item} />;
			})}
		</div>
	);
};

export default HighlightsBlock;

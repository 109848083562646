import React from "react";

import { replaceNonBreakingSymbol } from "@shared/text/text.utils";
import { EHighlightedTextCflDataLayoutType, IHighlightedTextCflData } from "./type";

import styles from "./highlighted-text.module.scss";

interface IProps {
	data: IHighlightedTextCflData;
}

const HighlightedText = ({ data }: IProps) => {
	if (!data) return null;

	const { layoutType, subtitle, title } = data;

	if (!title) return null;

	if (layoutType === EHighlightedTextCflDataLayoutType.Handwriting) {
		return (
			<p className={styles.handwriting} data-testid="HighlightedText_handwriting-title">
				{replaceNonBreakingSymbol(title)}
			</p>
		);
	}

	return (
		<div className={styles.highlightedStat}>
			<h2 data-testid="HighlightedText_highlighted-stat-title">
				{replaceNonBreakingSymbol(title)}
			</h2>
			{subtitle && (
				<p data-testid="HighlightedText_highlighted-stat-subtitle">
					{replaceNonBreakingSymbol(subtitle)}
				</p>
			)}
		</div>
	);
};

export default HighlightedText;

import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { IAssetWithTextCflData } from "@components/ContentfulComponents/ComponentAssetWithText/type";
import { IHighlightedTextCflData } from "@components/ContentfulComponents/ComponentHighlightedText/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { ITextAssetAndCtasCflData } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import {
	EContentPagesContentBlockCflDisplayOptions,
	EContentPagesContentBlockCflLayout,
	EGridSize,
	IContentPagesContentBlockCflData,
	TPagesContentBlockData,
} from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { IHomeRecommendation } from "@components/ContentfulComponents/ComposeHome/type";

import HighlightsBlock from "@components/ContentfulComponents/ContentPagesContentBlock/ContentBlockComponents/HighlightsBlock/HighlightsBlock.component";
import dynamic from "next/dynamic";

interface IContentBlockContent {
	recommendation?: IHomeRecommendation;
	displayAs?: EContentPagesContentBlockCflDisplayOptions;
	data?: TPagesContentBlockData[];
	layout?: EContentPagesContentBlockCflLayout;
	absoluteUrl?: string;
	gridSize?: EGridSize;
}

const DynamicCardAndImageBlock = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ContentPagesContentBlock/ContentBlockComponents/CardAndImageBlock/CardAndImageBlock.component"
		)
);

const DynamicContentBlock = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ContentPagesContentBlock/ContentBlockComponents/ContentBlock/ContentBlock.component"
		)
);
const DynamicFeaturedLinksBlock = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ContentPagesContentBlock/ContentBlockComponents/FeaturedLinksBlock/FeaturedLinksBlock.component"
		)
);

const DynamicImagesBlock = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ContentPagesContentBlock/ContentBlockComponents/ImagesBlock/ImagesBlock.component"
		)
);

const DynamicTextAssetAndCtasBlock = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ContentPagesContentBlock/ContentBlockComponents/TextAssetAndCtasBlock/TextAssetAndCtasBlock.component"
		)
);

const ContentBlockContent = ({
	displayAs,
	data,
	layout,
	absoluteUrl,
	gridSize,
	recommendation,
}: IContentBlockContent) => {
	if (!displayAs) return null;

	switch (displayAs) {
		case EContentPagesContentBlockCflDisplayOptions.CardAndImage:
		case EContentPagesContentBlockCflDisplayOptions.Mosaic:
			return (
				<DynamicCardAndImageBlock
					data={data as IAssetWithTextCflData[]}
					layout={layout}
					absoluteUrl={absoluteUrl}
					loading
					data-testid="ContentBlockContent_card-and-image-block"
				/>
			);
		case EContentPagesContentBlockCflDisplayOptions.ContentBlock:
			return (
				<DynamicContentBlock
					data={data as IContentPagesContentBlockCflData[]}
					gridSize={gridSize}
					loading
					recommendation={recommendation}
					data-testid="ContentBlockContent_content-block"
				/>
			);
		case EContentPagesContentBlockCflDisplayOptions.FeaturedLinks:
			return (
				<DynamicFeaturedLinksBlock
					data={data as ILinkCflData[]}
					data-testid="ContentBlockContent_featured-links-block"
				/>
			);
		case EContentPagesContentBlockCflDisplayOptions.Handwriting:
		case EContentPagesContentBlockCflDisplayOptions.Highlights:
			return <HighlightsBlock data={data as IHighlightedTextCflData[]} layout={layout} />;
		case EContentPagesContentBlockCflDisplayOptions.Images:
			return (
				<DynamicImagesBlock
					data={data as IAssetCflData[]}
					layout={layout}
					data-testid="ContentBlockContent_images-block"
				/>
			);
		case EContentPagesContentBlockCflDisplayOptions.TextImageCtas:
			return (
				<DynamicTextAssetAndCtasBlock
					data={data as ITextAssetAndCtasCflData[]}
					absoluteUrl={absoluteUrl}
					data-testid="ContentBlockContent_text-asset-ctas-block"
				/>
			);

		default:
			return null;
	}
};

export default ContentBlockContent;

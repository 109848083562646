import { EComponentTypenames, ISysCfl } from "@shared/contentful/contentful.types";

export enum EHighlightedTextCflDataLayoutType {
	Handwriting = "handwriting text",
	Stat = "highlighted stat",
}

export interface IHighlightedTextCflData extends ISysCfl {
	__typename: EComponentTypenames;
	identifier?: string;
	layoutType?: EHighlightedTextCflDataLayoutType;
	title?: string;
	subtitle?: string;
}

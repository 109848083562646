import React from "react";

import { colors } from "@shared/styles/theme";

import {
	EGridSize,
	ETitleAlignment,
	IContentPagesContentBlockCflData,
} from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { IHomeRecommendation } from "@components/ContentfulComponents/ComposeHome/type";
import {
	convertBackgroundColorInHexCode,
	isRightMarginRemoved,
} from "@components/ContentfulComponents/ContentPagesContentBlock/utils";
import ContentBlockContent from "@components/ContentfulComponents/ContentPagesContentBlock/ContentBlockComponents/ContentBlockContent/ContentBlockContent.component";

import styles from "./content-pages-content-block.module.scss";

interface IProps {
	data: IContentPagesContentBlockCflData;
	recommendation?: IHomeRecommendation;
	wrapperClassName?: string;
	absoluteUrl?: string;
	gridSize?: EGridSize;
	alignTitle?: ETitleAlignment;
}

const ContentPagesContentBlock = ({
	data,
	wrapperClassName = "",
	absoluteUrl,
	gridSize = EGridSize.Medium,
	alignTitle = ETitleAlignment.Left,
	recommendation,
}: IProps) => {
	if (!data) return null;

	const { title, displayAs, intro, layout, backgroundColor, contentCollection } = data;

	if (!displayAs) return null;

	const noRightMargin = isRightMarginRemoved(displayAs);
	const bgColor = convertBackgroundColorInHexCode(backgroundColor);

	return (
		<div
			className={`${styles.contentPagesBlockWrapper} ${
				bgColor === colors.transparent ? "" : styles.extraSpacing
			} ${wrapperClassName}`}
			style={{ background: bgColor }}
			data-testid="ContentPagesContentBlock_wrapper"
		>
			<div
				data-testid="ContentPagesContentBlock_inner-wrapper"
				className={`container-grid ${gridSize}-grid ${styles.innerWrapper} ${
					noRightMargin ? styles.removeRightMargin : ""
				}`}
			>
				{title && (
					<h2
						className={`${styles.title} ${
							alignTitle === ETitleAlignment.Center ? styles.center : ""
						}`}
						data-testid="ContentPagesContentBlock_title"
					>
						{title}
					</h2>
				)}
				{intro && (
					<p className={styles.intro} data-testid="ContentPagesContentBlock_intro">
						{intro}
					</p>
				)}
				<ContentBlockContent
					displayAs={displayAs}
					data={contentCollection?.items}
					layout={layout}
					absoluteUrl={absoluteUrl}
					gridSize={gridSize}
					recommendation={recommendation}
				/>
			</div>
		</div>
	);
};

export default ContentPagesContentBlock;
